.container {
	position: relative;
	width: 100px;
	height: 80px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	display: inline-block;
}

.layer {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	/*border: 1px solid rgba(20, 200, 20, .6);*/
	/*background-color: rgba(20, 220, 20, .6);*/
}

.markerwrapper {
	position: absolute;
	width: calc(100% * 0.1);
	height: 100%;
	top: 0;
	left: 50%;
}

.dial {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.marker {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: calc(100% * 0.2);
}

.direction {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #666;
	height: 100%;
	width: 100%;
	font-size: 1.2rem;
	/*font-size: .6em;*/
}
