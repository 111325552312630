.container {
  min-width: 280px;
  padding: 0px 0px;
}

@media (min-width: 800px) {
  .container {
    padding: 2px 30px;
  }
}

.layer {
  /*width: 300px;*/
  max-width: 350px;
  height: 180px;
	display: flex;
  flex-wrap: wrap;
	/*border: 1px solid #333333;*/
	border-radius: 10px;
	background: #fff;
	padding: 5px 10px 5px 10px;
  margin-bottom: 5px;
}

.location {
  color: #999;
  text-align: left;
  margin-top:0px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: .5em;
}

.location span {
  color: #ddd;
}

.title {
	color: #fff;
	text-align: left;
	vertical-align: top;
  font-size: 1.2em;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
}

.title p {
  margin-bottom: -10px;
  margin-top: 0px;
}

.status {
	color: #ddd;
	text-align: left;
  font-size: .5em;
  margin-bottom: 10px;
  margin-top: 0px;
}

.detail {
	flex-grow: 1;
	color: #fff;
	text-align: center;
	vertical-align: top;
  font-size: 1em;
	margin: 10px;
}

.detail p {
	color: #666;
  font-size: .9em;
	margin: 10px 20px 5px;
}

.detail span {
	color: #333;
  font-size: 1em;
  font-weight: bold;
}

.offline {
  height: 7px;
  width: 7px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0px !important;
  margin-right: 5px;
  margin-left: 1px;
}

.online {
  height: 7px;
  width: 7px;
  background-color: #34b248;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0px !important;
  margin-right: 5px;
  margin-left: 1px;
}