body {
  background-color: #282c34;
}

.App {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
}

.Content {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
/*  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  justify-items: center;
*/}

.App-link {
  color: #61dafb;
}
